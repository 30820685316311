import React from 'react';
import './Product.css';
import 'antd/dist/antd.css';
import {PageHeader} from 'antd';
import ARButton from './ARButton.js';
import {ProductList} from './products/Configs.js';
import ReactGA from 'react-ga';

// const bounceControl = () => {
//     document
//       .getElementById("noBounce")
//       .addEventListener("touchstart", function() {
//         document.body.style.position = "absolute";
  
//         // Stops the pull to refresh functionality
//         inobounce.enable();
//       });
  
//     document
//       .getElementById("noBounce")
//       .addEventListener("touchend", function() {
//         // Test if thet imeout does anything
//         setTimeout((document.body.style.position = ""), 3000);
  
//         // Continues the pull to refresh functionality
//         inobounce.disable();
  
//       });
//   };

class Product extends React.Component {
    render(){
        const productId = this.props.match.params.productId;
        const product = ProductList[productId];
        console.log(productId);
        ReactGA.initialize("UA-63905846-9");
        ReactGA.set({ dimension1: "Salesforce" });
        ReactGA.pageview(product.name + ' | SFDC Demo');
        return(
            <div>
                <PageHeader
                    style={{
                    border: '1px solid rgb(235, 237, 240)',
                    }}
                    onBack={() => window.history.back()}
                    title={product.name}
                />
                <div id="noBounce">
                    <div id="playerTarget" style={{height:400, width:"96%", marginLeft:"auto", marginRight:"auto" }}></div>
                </div>
                {!product.usdz || !product.glb ? <p></p> : <ARButton productName={product.name} usdz={product.usdz} glb={product.glb}></ARButton>}
                
            </div>
        );
    }

    componentDidMount(){
        const productId = this.props.match.params.productId;
        const product = ProductList[productId];
        const api = window.claraplayer("playerTarget");
        api.sceneIO.fetchAndUse(product.clara);
        api.player.hideTool("orbit");
        api.player.hideTool("pan");
        api.player.hideTool("zoom");
        api.player.hideTool("home");
        document.getElementById("noBounce").addEventListener("touchstart", function() {
            document.body.style.position = "absolute";
            // Stops the pull to refresh functionality
            window.iNoBounce.enable();
        });

        document.getElementById("noBounce").addEventListener("touchend", function() {
            // Test if thet imeout does anything
            setTimeout((document.body.style.position = ""), 3000);

            // Continues the pull to refresh functionality
            window.iNoBounce.disable();
      });
        
    }

}

export default Product
