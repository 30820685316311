const ProductList = {
            "1":{
                "usdz" : "https://threekit.s3.amazonaws.com/usdz/KitchenAid+Mixer+Empire+Red.usdz",
                "imageURL":"https://images-na.ssl-images-amazon.com/images/I/61CVcwZ4SrL._SL1500_.jpg",
                "glb" : "https://threekit.s3.amazonaws.com/glb/KitchenAid+Mixer+Empire+Red.glb",
                "clara" : "ecacaff0-199f-45a2-994b-2bd55d1c19e0",
                "name" :"Empire Red Mixer",
                "brand": "Kitchen Accessory"
            },
            // "2":{
            //     "usdz" : "https://threekit.s3.amazonaws.com/usdz/BeoSound+2+Silver.usdz",
            //     "glb" : "https://threekit.s3.amazonaws.com/glb/BeoSound+2+Silver.glb",
            //     "clara" : "aeb49432-607d-46b7-89d3-5f91f90709ba",
            //     "name" :"Beosound 2",
            //     "brand": "Speaker",
            //     "imageURL":"https://images.ctfassets.net/8cd2csgvqd3m/1RCrQvztb5JnWoMa6q1b38/810bed893cf982222a66ca6723aba43b/beosound-2-natural-transparrent-image-1.png?fm=png&q=100&w=720&h=720&fit=fill"
            // },
            // "3":{
            //     "usdz" : "https://threekit.s3.amazonaws.com/usdz/Pfister+Auden+Polished+Chrome+2Handle+4in++Seeger.usdz",
            //     "glb" : "https://threekit.s3.amazonaws.com/glb/Pfister+Auden+Polished+Chrome+2Handle+4in++Seeger.glb",
            //     "clara" : "584bcc28-7d7b-46b4-aca1-9d2d7a666c30",
            //     "name" :"Auden Polished Chrome",
            //     "brand" : "Tap",
            //     "imageURL" : "https://images.pfisterfaucets.com/is/image/WebAssets/pf_auden_lf-048-adcc_c1?wid=600&qlt=100&resMode=sharp&fmt=png-alpha"
            // },
            "4":{
                "usdz" : "https://solutions-engineering.s3.amazonaws.com/sfdc-demo/nespresso-sfdc-demo-live-live.usdz",
                "glb" : "https://solutions-engineering.s3.amazonaws.com/sfdc-demo/NespressoSFDCDEMOLIVELIVE.glb",
                "clara" : "88b59c44-000f-4cef-8896-2caf38835cbb",
                "name" :"Electric Red Pixie",
                "brand" : "Kitchen Accessory",
                "imageURL" : "https://www.nespresso.com/ecom/medias/sys_master/public/10392434999326/M-1002-Nespresso-C60-Pixie-Electric-Red-2000x2000.png"
            },
            "5":{
                "imageURL" : "https://i.imgur.com/6K1iauU.jpg",
                "usdz" : "https://threekit.s3.amazonaws.com/usdz/Teknion+Dreamforce.usdz",
                "clara": "ba12250f-54e9-4cc4-beea-766e1de2e92e",
                "name": "Sabrina Chair",
                "brand" : "Office Furniture",
                "glb" : "https://threekit.s3.amazonaws.com/glb/Teknion+Dreamforce.glb"
            },
            "6":{
                "imageURL" : "https://product-images.weber.com/Grill-Images/Charcoal/14401001B_REV.png?fit=fill&w=1000",
                "usdz" : "https://threekit.s3.amazonaws.com/usdz/Weber+BBQ++ThreeKit.com+July+17.usdz",
                "clara": "20aba898-62e0-4c5d-a1a9-14120c9fee46",
                "name": "Original Kettle Charcoal Grill 22\"",
                "brand" : "Outdoor Furniture",
                "glb" : "https://threekit.s3.amazonaws.com/glb/Weber+BBQ++ThreeKit.com+July+17.glb"
            }, 
            "7":{
                "imageURL" : "https://i.imgur.com/LXj5Bd7.png",
                // "usdz" : "https://threekit.s3.amazonaws.com/usdz/Weber+BBQ++ThreeKit.com+July+17.usdz",
                "clara": "1115041b-1b9e-412b-a55c-06d2a331c5b6",
                "name": "Luxury Watch",
                "brand" : "Fashion",
                // "glb" : "https://threekit.s3.amazonaws.com/glb/Weber+BBQ++ThreeKit.com+July+17.glb"
            },
            // "8":{
            //     "imageURL" : "https://solutions-engineering.s3.amazonaws.com/sfdc-demo/chair.png",
            //     "usdz" : "https://solutions-engineering.s3.amazonaws.com/sfdc-demo/sternzeit-designs-salesforce-quickdemo-site.usdz",
            //     "clara": "c044c0d2-16a5-4d62-855c-e9fdda54683a",
            //     "name": "European Chair",
            //     "brand" : "Home Furniture",
            //     "glb" : "https://solutions-engineering.s3.amazonaws.com/sfdc-demo/SternzeitDesignsSalesforceQuickdemoSite.glb"
            // }
        };
export {ProductList};