import React from "react";
import { Layout, Row, Col } from "antd";
// import logo from './logo.svg';
import Products from "./Products.js";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Product from "./Product";

const { Header, Content, Footer } = Layout;
const My404Component = () => {
  return (
    <div>
      <h1>Not found</h1>
    </div>
  );
};

function App(props) {
  window.iNoBounce.disable();
  return (
    <div className="App">
      <Layout className="layout">
        <Header id="header">
          <span id="link-span" style={{ display: "inline-block" }}>
            <a href="/">3D + Augmented Reality</a>
          </span>
        </Header>

        {/* <Header id="header2">

        </Header> */}

        <Content style={{ padding: "1em" }}>
          <Router>
            <Switch>
              <Route path="/product/:productId" component={Product}></Route>
              <Route path="/" component={Products}></Route>
            </Switch>
          </Router>
        </Content>

        <Footer style={{ textAlign: "center" }}>Threekit ©${new Date().getFullYear()}</Footer>
      </Layout>
    </div>
  );
}

export default App;
