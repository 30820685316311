import React from 'react';
// import logo from './logo.svg';
import './Product.css';
import 'antd/dist/antd.css';
import { Icon, Card, List, Layout, Button, PageHeader } from 'antd';
import { isBrowser, isMobile } from "react-device-detect";
// import ARButton from './ARButton.js';
import { Link } from 'react-router-dom';
import { ProductList } from './products/Configs.js'
import ReactGA from 'react-ga';

// import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';


const { Header, Content, Footer } = Layout;

const productIds = [];
for (var i in ProductList) {
    productIds.push(i);
}

class Products extends React.Component {


    render() {
        ReactGA.initialize("UA-63905846-9");
        ReactGA.set({ dimension1: "Salesforce" });
        ReactGA.pageview("Landing Page | SFDC Demo");
        return (
            <div id="product-content">
                {/* <PageHeader
                    ghost={false}
                    style={{ marginBottom: '10px' }}
                >
                    <Content>
                        <b>How it works</b><br />
              Click on an item to see it in 3D. If you are visitng on a mobile device, click "View in AR" to see the item in your space.</Content>
                </PageHeader> */}
                <List grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }} dataSource={productIds} renderItem={item => (
                    <div>
                        {ProductList[item].external ? (
                            //    <Link to={"/product/" + item}>
                            <List.Item >

                                <Card
                                    style={{ maxHeight: 400, height: 300 }}
                                    //cover={<Icon type="bug" style={{ fontSize: '200px', color: '#08c' }}/>}
                                    cover={<a href={ProductList[item].link} target="_blank" rel="noopener noreferrer"><img style={{ maxHeight: 200, width: (isMobile ? "auto" : "auto"), marginLeft: "auto", marginRight: "auto" }} src={ProductList[item].imageURL}></img></a>}

                                >
                                    <Card.Meta
                                        title={`${ProductList[item].name}`}
                                        description={ProductList[item].brand}
                                    />
                                    {/* {ProductList[item].external} */}
                                </Card>
                            </List.Item>
                            //    </Link>
                        ) : (<Link to={"/product/" + item}>
                            <List.Item>

                                <Card
                                    style={{ maxHeight: 400, height: 300, margin: '5px' }}
                                    //cover={<Icon type="bug" style={{ fontSize: '200px', color: '#08c' }}/>}
                                    actions={[
                                        <Button href={"/product/" + item}>See in 3D</Button>
                                     
                                      ]}
                                    cover={<img style={{ maxHeight: 200, width: (isMobile ? "auto" : "auto"), marginLeft: "auto", marginRight: "auto" }} src={ProductList[item].imageURL}></img>}
                                >
                                    <Card.Meta
                                        title={`${ProductList[item].name}`}

                                        description={`${ProductList[item].brand}`}
                                    />
                                    {/* {ProductList[item].external} */}
                                </Card>
                            </List.Item>
                        </Link>)}

                    </div>
                )
                }>
                </List>
                <PageHeader
                    ghost={false}
                    style={{ margin: '5px' }}
                >
                    <Content>
                        <List
                            itemLayout="horizontal">
                            {/* <List.Item>
                                <a href="https://www.youtube.com/watch?v=bdP9uc7O3kI&feature=youtu.be" target="_blank" rel="noopener noreferrer">Check out the Threekit and Salesforce CPQ Demo</a>
                            </List.Item> */}
                            <List.Item>
                                <a href="https://www.threekit.com/threekit-for-salesforce" target="_blank" rel="noopener noreferrer">Learn more about Threekit and Salesforce</a>
                            </List.Item>
                            <List.Item>
                                <a href="https://www.threekit.com/" target="_blank" rel="noopener noreferrer">Powered by Threekit 3D and AR</a>
                            </List.Item>
                            <List.Item>
                                <a href="https://www.threekit.com/" target="_blank" rel="noopener noreferrer">Learn more at Threekit.com</a>
                            </List.Item>
                        </List></Content>
                </PageHeader>
            </div>
        );
    }
}

export default Products;